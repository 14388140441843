@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutToRight {
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutToLeft {
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

.no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (min-width: 768px) {
  .custom-modal-content {
    position: fixed;
    right: 2.5rem;
    top: 11.25rem;
    left: auto;
    bottom: auto;
  }
  .slide-in-left {
    animation: slideInFromLeft 1s forwards;
  }

  .slide-out-left {
    animation: slideOutToLeft 1s forwards;
  }

  .slide-in-right {
    animation: slideInFromRight 1s forwards;
  }

  .slide-out-right {
    animation: slideOutToRight 1s forwards;
  }
}

/* for adjusting the height of dropdown on small screen */
@media (max-height: 750px) {
  .small-screen-height {
    max-height: 150px;
  }
}
.css-nn3ywf-option {
  background: #2b8abc !important;
  color: white;
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.placeholder-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  pointer-events: none;
  white-space: nowrap;
  overflow: hidden;
  left: 0;
  right: 0;
}

.placeholder-text {
  display: inline-block;
  white-space: nowrap;
  position: absolute;
  animation: scroll 20s linear infinite;
  color: #a7a7a7;
  font-size: 12px;
  animation-delay: 4s;
  animation-timing-function: linear;
}

.hidden-text {
  position: absolute;
  visibility: hidden;
  white-space: nowrap;
}
.toolTipButton button {
  outline: none;
}

/* Hide scrollbars but keep scrolling for all elements */
* {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
/* For WebKit-based browsers (Chrome, Safari) */
*::-webkit-scrollbar {
  display: none; /* Hide the scrollbar */
}

/*For Hiding Microsoft Edge Default Eye Icon*/
input[type='password']::-ms-reveal {
  display: none;
}

.print-heading {
  display: none; /* Hide on screen */
}

@media print {
  .print-heading {
    display: block;
  }

  .print-content {
    padding: 2rem;
    max-height: none;
    overflow: visible;
  }
}

.chatTemplate button {
  width: 100%;
  text-align: start;
}
.pg-viewer-wrapper {
  width: 100%;
}
.pg-viewer-wrapper .document-container {
  width: 100%;
  height: 100%;
}
canvas {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .month-picker > .rmp-container .rmp-popup {
    margin-top: 27px;
    margin-left: -7px;
    border-radius: 6px;
    width: 130px;
  }
  .month-picker > .rmp-container .rmp-popup .rmp-pad {
    width: 130px;
  }
  .month-picker > .rmp-container .rmp-popup .rmp-pad li {
    padding: 0 !important;
    line-height: 30px;
  }
  .month-picker > .rmp-container .rmp-popup .rmp-pad ul {
    height: 150px;
    overflow-y: scroll;
  }
  .month-picker > .rmp-container .rmp-popup.light .rmp-pad .prev,
  .month-picker > .rmp-container .rmp-popup.light .rmp-pad .next {
    height: 20px !important;
    width: 20px !important;
    line-height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn {
    margin: 2px;
    width: 100%;
  }
  .month-picker > .rmp-container .rmp-popup .rmp-pad > div label,
  .month-picker > .rmp-container .rmp-popup .rmp-pad > div i {
    line-height: 30px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
  }
}
.month-picker > .rmp-container .rmp-popup .rmp-pad > div label {
  color: #2b8abc;
}
.month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn {
  font-size: 14px;
}
.month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn:hover {
  background: #2b8abc;
  color: white;
  border-radius: 4px;
}

.month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active,
.month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active:hover {
  background: #2b8abc;
}
